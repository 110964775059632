@import "../variables.modules";

.light {
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #292e49,
    #536976
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #292e49,
    #536976
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: $dark;
  transition: all 400ms;
}

//.dark {
//  background-color: $dark;
//  color: $white;
//  transition: all 400ms;
//  a, i {
//    color: $white;
//    transition: color 400ms;
//  }
//  a:visited {
//    color: $white;
//  }
//  i:hover {
//    color: $purple;
//  }
//  footer {
//    color: $white;
//  }
//
//  nav {
//    background-color: $dark;
//    transition: $transition;
//  }
//
//  div > section > div:first-child {
//    background-color: #575e64;
//    i:first-child {
//      color: $red;
//    }
//    i:nth-child(2) {
//      color: $yellow;
//    }
//    i:nth-child(3) {
//      color: $green;
//    }
//  }
//
//
//  & > div div {
//    i {
//      color: white;
//    }
//  }
//}
