$purple: #8d53ff;
$pink: #ca6be6;
$white: #f8f8f8;
$dark: #1f1f1f;
$red: #ff6057;
$yellow: #ffbd2e;
$green: #27c93f;
$lightgray: #c9c9c9;
$caprionTextColor: rgb(173, 172, 209, 0.6);

$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
}
